const {
  REACT_APP_ENV,
  REACT_APP_API_URL,
  REACT_APP_X_TOKEN,
  REACT_APP_COGNITO_POOL_ID,
  REACT_APP_COGNITO_POOL_CLIENT_ID,
  REACT_APP_KEY_CIPHER,
  REACT_APP_DOMAIN,
} = process.env;

export {
  REACT_APP_ENV,
  REACT_APP_API_URL,
  REACT_APP_X_TOKEN,
  REACT_APP_COGNITO_POOL_ID,
  REACT_APP_COGNITO_POOL_CLIENT_ID,
  REACT_APP_KEY_CIPHER,
  REACT_APP_DOMAIN
};
