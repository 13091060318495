import { action, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { Amplify, Storage } from '~/services';
import { getTenantApps, getUserTenants } from '~/services/api/tenant';

const amplify = new Amplify();

export default class UserStore {

  @observable
  data: Partial<User.UserInfOutput> = {};

  @observable
  tenants: Partial<User.Tenant[]> = [];

  constructor() {
    makeObservable(this);

    makePersistable(this, {
      name: 'user',
      properties: ['data', 'tenants' ],
      storage: window.localStorage,
    });
  }

  @action
  onClearUserData = (): void => {
    this.data = {};
  }

  @action
  onSetUserData = (data: Partial<User.UserInfOutput>): void => {
    this.data = data;
  };

  @action
  onCheckSession = async (): Promise<boolean> => {
    const success = await amplify.verifyUser();
    return !!success;
  };

  @action
  logout = (): void => {
    const success = amplify.logout();
    if (success) Storage.clearStorage();
  };

  @action
  fetchUserTenants = async (): Promise<void> => {
    try {
      const tenants = await getUserTenants();
      this.tenants = tenants;
    } catch (error) {
      console.error(error);
    }
  };

  @action
  fetchTenantApps = async (tenantId): Promise<[]> => {
    const apps = await getTenantApps(tenantId);
    return apps;
  }
}