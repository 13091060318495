// src/scenes/GroupSelection/index.tsx
import React, { useEffect, useState } from 'react';
import { useStores } from '~/utils';
import { Card, Input } from 'antd'; // Utilizando Ant Design para a UI
import './style.css'; // Importando o CSS
import { saveTenantId, setTenantIdCookie } from '~/utils/tenant'; // Importando as funções para salvar o tenant_id
import { observer } from 'mobx-react';
import Header from '~/components/Header'; // Importando o Header

const { Search } = Input;

const GroupSelection = () => {
    const { user } = useStores();
    const [filteredGroups, setFilteredGroups] = useState([]);

    useEffect(() => {
        // Simulando a busca dos grupos
        user.fetchUserTenants();
    }, []);

    const handleTenantSelect = (tenant) => {
        // Armazenar o tenant_id e tenant_name no LocalStorage e Cookies
        const tenantId = tenant.tenant_id;
        const tenantName = tenant.tenant_name;
        if (tenantId) {
            saveTenantId(tenantId);
            setTenantIdCookie(tenantId, 7); // Armazena por 7 dias
            localStorage.setItem('tenant_name', tenantName); // Armazena o tenant_name
        }

        // Redirecionar para home
        window.location.href = '/'; // Redirecionar para a página principal
    };

    const handleSearch = (value) => {
        const filtered = user.tenants.filter(tenant =>
            tenant.tenant_name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredGroups(filtered);
    };

    return (
        <div className="group-selection">
            <Header /> {/* Adicionando o Header */}
            <div className="header">
                <h1>Selecione seu Workspace</h1>
                <p>Você pode alterar seu Workspace a qualquer momento.</p>
                <Search
                    className="search-bar"
                    placeholder="Find a space"
                    onSearch={handleSearch}
                    enterButton
                    style={{ maxWidth: 400, margin: '20px auto' }}
                />
            </div>
            <div className="group-list">
                {user.tenants.map((tenant) => (
                    <div key={tenant.tenant_id} className="group-card-link" onClick={() => handleTenantSelect(tenant)}>
                        <Card
                            className="group-card"
                            cover={<img alt={tenant.tenant_name} src={`data:image/png;base64,${tenant.logo_img_base64}`} />}
                        >
                            <div className="card-content">
                                <div className="card-title">{tenant.tenant_name}</div>
                            </div>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default observer(GroupSelection);
