// Função para salvar o tenant_id
export function saveTenantId(tenantId: string) {
    console.log('Saving tenant_id to localStorage:', tenantId); // Adicione este log
    localStorage.setItem('tenant_id', tenantId);
}

// Função para recuperar o tenant_id
export function getTenantId() {
    return localStorage.getItem('tenant_id');
}

// Função para salvar o tenant_id em um cookie
export function setTenantIdCookie(tenantId: string, days: number) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = "tenant_id=" + tenantId + ";" + expires + ";path=/";
    console.log('Tenant ID armazenado no cookie:', tenantId); // Adicione este log
}

// Função para recuperar o tenant_id de um cookie
export function getTenantIdCookie() {
    const name = "tenant_id=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
