import { 
  STORYGEN,
  STORYGENLOCAL, 
  STORYGENDEV,
  STORYGENHML,
  LOGOUNIT, 
  TESTCASEGEN, 
  TESTCASEGENLOCAL, 
  TESTCASEGENDEV,
  TESTCASEGENHML,
  TDM, 
  API, 
  LOGOIQD, 
  SV,  
  GPT,
  APILOCAL,
  APIDEV,
  APIHML,
  EPICGEN,
  EPICGENLOCAL,
  EPICGENDEV,
  EPICGENHML
  } from '~/assets/img';

const PRIME = {
  client: 'Prime',
  icon: 'https://www.primecontrol.com.br/wp-content/uploads/2021/01/novalogo-primecontrol-website.png',
  apps: [
    {
      id: 'epic_gen',
      name: 'Epic Gen',
      description: 'Sua IA para geração de Épicos.',
      url: {
        "DEV": 'https://app-ai-dev.primecontrol.com.br/epic',
        "HML": 'https://app-ai-hml.primecontrol.com.br/epic',
        "PRD": 'https://app-ai.primecontrol.com.br/epic',
        "LOCAL": 'http://localhost:3001/epic'
      },
      img: EPICGEN,
      visible: true,
    },
    {
      id: 'story_gen',
      name: 'Story Gen',
      description: 'Sua IA para geração de Histórias de Usuário.',
      url: {
        "DEV": 'https://app-ai-dev.primecontrol.com.br/story',
        "HML": 'https://app-ai-hml.primecontrol.com.br/story',
        "PRD": 'https://app-ai.primecontrol.com.br/story',
        "LOCAL": 'http://localhost:3002/story',
      },
      img: STORYGEN,
      visible: true,
    },
    {
      id: 'unit_test',
      name: 'Unit Test',
      description: '',
      url: {
        "DEV": 'https://app-ai-dev.primecontrol.com.br/unit',
        "HML": 'https://app-ai-hml.primecontrol.com.br/unit',
        "PRD": 'https://app-ai.primecontrol.com.br/unit',
        "LOCAL": 'http://localhost:3007/unit'
      },
      img: LOGOUNIT,
      visible: true,
    },
    {
      id: 'test_case_gen',
      name: 'Test Case Gen',
      description: 'Sua IA para geração de Casos de Teste.',
      url: {
        "DEV": 'https://app-ai-dev.primecontrol.com.br/tests',
        "HML": 'https://app-ai-hml.primecontrol.com.br/tests',
        "PRD": 'https://app-ai.primecontrol.com.br/tests',
        "LOCAL": 'http://localhost:3003/tests'
      },
      img: TESTCASEGEN,
      visible: true,
    },
    {
      id: 'api_test_gen',
      name: 'API Test Gen',
      description: 'Sistema de geração de testes automatizados de API.',
      url: {
        "DEV": 'https://test-coder-dev.primecontrol.com.br',
        "HML": 'https://test-coder-hml.primecontrol.com.br',
        "PRD": 'https://testcoder.primecontrol.com.br',
        "LOCAL": 'http://localhost:3004/api',
      },
      img: API,
      visible: true,
    },
    {
      id: 'prime_tdm',
      name: 'TDM',
      description: '',
      url: {
        "DEV": 'https://tdm.primecontrol.com.br',
        "HML": 'https://tdm.primecontrol.com.br',
        "PRD": 'https://tdm.primecontrol.com.br',
        "LOCAL": 'http://localhost:3006'
      },
      img: TDM,
      visible: false,
    },
    {
      id: 'prime_iqd',
      name: 'IQD',
      description: '',
      url: {
        "DEV": 'https://iqd.primecontrol.com.br',
        "HML": 'https://iqd.primecontrol.com.br',
        "PRD": 'https://iqd.primecontrol.com.br',
        "LOCAL": 'http://localhost:3008'
      },
      img: LOGOIQD,
      visible: true,
    },
    {
      id: 'prime_sv',
      name: 'PRIME SV',
      description: '',
      url: {
        "DEV": 'https://sv.primecontrol.com.br',
        "HML": 'https://sv.primecontrol.com.br',
        "PRD": 'https://sv.primecontrol.com.br',
        "LOCAL": 'http://localhost:3009'
      },
      img: SV,
      visible: false,
    },
    {
      id: 'gpt',
      name: 'PRIME GPT',
      description: '',
      url: {
        "DEV": 'https://gpt.primecontrol.com.br',
        "HML": 'https://gpt.primecontrol.com.br',
        "PRD": 'https://gpt.primecontrol.com.br',
        "LOCAL": 'https://gpt.primecontrol.com.br'
      },
      img: GPT,
      visible: true,
    }
  ],
};

export const CLIENTS = {
  PRIME,
};
