import { Request } from '~/services';

export const getUserTenants = async () => {
    try {
        const { data } = await Request.get('/tenant');
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getTenantApps = async (tenantId: string) => {
    try {
        const { data } = await Request.get(`/tenant/${tenantId}/apps`);
        return data;
    } catch (error) {
        throw new Error(error);
    }
}