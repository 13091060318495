import { AxiosInterceptorManager } from 'axios';
import { Amplify } from '~/services';	

type CustomRequest = {
  authorization?: string;
};

export const interceptorsAuthHeaders = async (
  config: any,
): Promise<any & CustomRequest> => {

  const amplify = new Amplify();

  return {
    ...config,
    headers: {
      ...config.headers,
      authorization: await amplify.getToken(),
    },
  };
};
