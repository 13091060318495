import React, { FC } from 'react';
import { Card, CardContainer, Content, Image, WIPTag } from './styles';
import { Tooltip } from '@material-ui/core';
import { useStores } from '~/utils';
import { Path } from '~/routes/routes.path';
import { If } from '~/components';
import { REACT_APP_ENV } from '~/utils';

type Props = {
  apps: App.Apps[];
};

const Accounts: FC<Props> = ({ apps }) => {
  const { routing } = useStores();

  const openApp = (name: string, url: string) => {
    if (name === 'UNIT TEST') {
      routing.replace(Path.UNITTEST);
    } else if (name === 'IQD') {
      routing.replace(Path.IQD);
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <Content>
      {apps.map(({ id, img, name, description, url, visible }) => (
        <Tooltip
          title={description ? `${name} | ${description}` : name}
          placement="top-start"
          key={name}
        >
          <CardContainer>
            <Card
              visible={!visible}
              onClick={() => (!visible ? () => {} : openApp(name, url[REACT_APP_ENV]))}
            >
              <Image src={img} alt={name} />
            </Card>
            <If condition={!visible}>
              <WIPTag>Em Construção</WIPTag>
            </If>
          </CardContainer>
        </Tooltip>
      ))}
    </Content>
  );
};

export default Accounts;
